.specialtyRow {
  min-width: 0;
  flex-shrink: 0;
  height: 50px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  font-family: var(--font-family);
  background-color: var(--surface-card);
  transition: border-radius 0.15s ease, box-shadow 0.15s ease,
    background-color 0.15s ease;
  color: var(--text-color);
  position: relative;
}

.upcomingRenewalOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  left: 0;
  top: 0;
  z-index: 50;
}

.upcomingRenewalOverlayColorLight {
  background-color: #faedca40;
}

.upcomingRenewalOverlayColorDark {
  background-color: #5e480344;
}

.specialtyRow:hover,
.specialtyRowHover {
  border-radius: 4px;
  background-color: var(--surface-100);
}

.rowContentSmall {
  width: 40px;
  display: flex;
  align-items: center;
  z-index: 100;
}

.rowContent {
  width: 160px;
  display: flex;
  gap: 6px;
  align-items: center;
  z-index: 100;
}

.knobText {
  color: var(--primary-color);
  font-weight: 600;
}

.signalContainer {
  display: flex;
  gap: 2px;
}

.twoLineText {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rowBreakContainer {
  display: flex;
  align-items: center;
  font-weight: bold;
  width: 100%;
  font-family: var(--font-family);
  color: var(--text-color-secondary);
  padding: 8px 14px;
  background-color: var(--surface-100);
}
