.report {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.summary {
  display: flex;
  align-items: center;
}
.explanation {
  margin-left: 12px;
  color: var(--text-color-secondary);
}
