.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  max-width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

.headerText {
  font-size: 48px;
  letter-spacing: 4.5px;
  margin-bottom: 12px;
  text-align: center;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 600px;
}

.subtext {
  font-size: 18px;
  margin-bottom: 24px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

@media (max-width: 700px) {
  .cardContainer {
    width: calc(100vw - 72px);
  }

  .headerText {
    font-size: 36px;
  }
}
