.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 164px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding-top: 300px;
  padding-bottom: 100px;
  padding-left: 16px;
  padding-right: 16px;
}

.headerTextImageContainer {
  width: min(calc(100%), 1000px);
}

.headerTextImage {
  max-width: 100%;
  margin-bottom: 48px;
}

.contentContainer {
  display: flex;
  gap: 48px;
}

.contentContainerReverse {
  flex-direction: row;
}

.contentPiece,
.contentPieceText {
  width: 680px;
}

.contentPieceText {
  display: flex;
  flex-direction: column;
}

.headerText {
  margin-top: 0px;
  font-size: 48px;
  letter-spacing: 4.5px;
  font-family: var(--font-family);
}

.infoText {
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 16px;
}

.highlightColor {
  color: #6ac6cd;
}

.imgFadeOut {
  -webkit-mask-image: linear-gradient(
    to right,
    black 650px,
    transparent 1000px
  );
  mask-image: linear-gradient(to right, black 600px, transparent 680px);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.loadingDocContainer {
  display: flex;
  align-items: center;
  position: relative;
  width: 360px;
  height: 500px;
  margin-bottom: -60px;
}

.loadingDoc2 {
  padding: 32px 16px;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: rgba(20, 20, 20, 0.9);
  transform: translateY(-32px) rotateX(45deg) rotateZ(30deg); /* Rotate on the X and Z axes */
  transform-origin: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  box-shadow: 0 0 10px rgba(106, 198, 205, 0.8),
    0 0 20px rgba(106, 198, 205, 0.6), 0 0 30px rgba(106, 198, 205, 0.4);
}

.contactButton {
  margin-top: 23px;
  letter-spacing: 1.5px;
}

.imgShadow {
  border-radius: 6px;
  box-shadow: 0 0 5px rgba(106, 198, 205, 0.8),
    0 0 10px rgba(106, 198, 205, 0.6), 0 0 15px rgba(106, 198, 205, 0.4);
}

.headerTextMobile {
  text-align: center;
  display: none;
}

@media (max-width: 1000px) {
  .headerTextImage {
    display: none;
  }

  .headerTextMobile {
    display: block;
  }
}

@media (max-width: 700px) {
  .headerText {
    font-size: 36px;
  }
}

@media (max-height: 700px) {
  .container {
    padding-top: 150px;
  }
}

@media (max-width: 1400px) {
  .contentContainer {
    flex-direction: column;
    gap: 32px;
    max-width: min(680px, 100%);
  }

  .contentPiece,
  .contentPieceText {
    width: auto;
    max-width: min(680px, 100%);
  }

  .contentContainerReverse {
    flex-direction: column-reverse;
  }

  .loadingDocContainer {
    position: relative;
    width: min(90%, 360px);
    height: 500px;
    margin-bottom: -60px;
  }
}
