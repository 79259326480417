.container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  min-width: 600px;
  min-height: 400px;
}

.rowContainer :global(.p-button) {
  width: 1rem;
  padding: 0;
}

.row {
  display: flex;
  gap: 8px;
}

.input {
  flex-grow: 1;
}

.rowContainer {
  width: 100%;
  gap: 2px;
}

.original,
.originalHeader {
  text-align: left;
}

.original {
  color: var(--text-color-secondary);
}

.before {
  text-decoration: line-through;
}

.before,
.after,
.cleared {
  border-radius: 2px;
  text-align: center;
}

.before.light {
  background-color: var(--red-600);
}
.after.light {
  background-color: var(--green-600);
}
.cleared.light {
  background-color: var(--yellow-600);
}

.before.dark {
  background-color: var(--red-600);
}
.after.dark {
  background-color: var(--green-600);
}
.cleared.dark {
  background-color: var(--yellow-600);
}
