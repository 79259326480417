.container {
  display: flex;
  width: 100%;
  height: calc(100% - 60px);
  padding: 20px;
  gap: 12px;
}

@media print {
  .container {
    display: none;
  }
}

.verticalContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 0;
  flex-grow: 1;
  overflow-y: auto;
}

.iframe {
  border: none;
  width: 100%;
  height: calc(100% - 5px);
  border-radius: 6px;
}

.headerText {
  font-family: var(--font-family);
  color: var(--text-color);
}

.tabView {
}

.tabView > :global(.p-tabview-nav-container) > :global(.p-tabview-nav-content) {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: var(--surface-card);
}

.tabView > :global(.p-tabview-panels) {
  padding: 0px;
  margin: 0px;
}

.topRightButtons {
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
}

.textButton {
  align-items: flex-start;
}

.statusContainer {
  margin-bottom: -12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.assigneeContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.assigneeName {
  font-size: 12px;
  font-weight: bold;
  color: var(--text-color);
}

.roundedHeaderPiece {
  background-color: var(--surface-100);
  border-radius: 6px;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 48px;
  min-width: 60px;
  align-items: flex-start;
  justify-content: center;
}
