.policyCard {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.perilCardTitle {
  font-size: 20px;
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.perilCard {
  box-shadow: none;
  border: 1px solid var(--surface-border);
  background-color: var(--surface-100);
}

.perilCard > div {
  padding-bottom: 0px;
}

.perilCardContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.condition {
  display: flex;
  align-items: center;
  gap: 4px;
}

.deductible {
  font-weight: bold;
}

.policyExcerpt {
  white-space: pre-wrap;
  font-style: italic;
  font-size: 14px;
  color: var(--text-color-secondary);
}

.verticalDivider {
  width: 2px;
  background-color: var(--surface-border);
  flex-shrink: 0;
  margin-left: 4px;
}

.verticalContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.policyDocTitle {
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
  display: flex;
}

.excerptContainer {
  margin-top: 8px;
  display: flex;
  gap: 16px;
}

.tinyButton {
  padding: 4px;
}

.deductibleContainer {
  height: 24px;
  display: flex;
  gap: 4px;
  align-items: center;
}
