.container {
  min-width: 750px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.row {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
}

.buttons {
  flex-basis: content;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
