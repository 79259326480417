.columnMappingRow {
  display: flex;
  align-items: center;
  gap: 20px;
}

.columnMappingSet {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}

.columnText {
  width: 220px;
  font-size: 18px;
  border-radius: 6px;
  padding: 8px 12px;
  border: 1px solid;
}

.dropdownPanel div {
  max-height: 500px !important;
}

.buttonContainer {
  display: flex;
  gap: 8px;
  margin-top: 2px;
  margin-bottom: 8px;
  align-items: center;
}
