.subtitleContentsContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.subtitleContentsContainerVertical {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.subtitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table > *,
.table > * > * {
  transform: rotateX(180deg);
}

.cellTextWrapper {
  display: flex;
  min-width: 0px;
}

.cellText {
  display: flex;
  align-items: center;
  gap: 4px;
}

.flaggedInput {
  padding: 4px;
  border-radius: 6px;
  margin-left: -4px;
}

.header {
  display: flex;
  align-items: center;
  gap: 4px;
}

.editButton {
  font-size: 0.75rem;
  cursor: pointer;
}
