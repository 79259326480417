.printContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.humanReviewItemsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.humanReviewItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.humanReviewItemRow {
  display: flex;
  gap: 8px;
  align-items: center;
}

.warningHorizontalContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.warningVerticalContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.verticalContainerSmallGap {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.perilCard {
  box-shadow: none;

  background-color: transparent;
}

.perilCard > div {
  padding-bottom: 0px;
}

.smallButton {
  width: 24px;
  height: 24px;
}

.buttonGroup {
  margin-left: -2px;
  display: flex;
  align-items: center;
  gap: 2px;
}

.policyExcerpt {
  white-space: pre-wrap;
  font-style: italic;
  font-size: 14px;
  color: var(--text-color-secondary);
}

.verticalDivider {
  width: 2px;
  background-color: var(--surface-border);
  flex-shrink: 0;
}

.excerptContainer {
  display: flex;
  gap: 16px;
}

.customMessage {
  font-size: 14px;
  padding: 6px 12px;
}

.exclusionHeader {
  display: flex;
  align-items: center;
  gap: 8px;
}
