.avatar {
  flex-shrink: 0;
}

.avatarSmall {
  height: 24px;
  width: 24px;
}

.avatarLarge {
  height: 32px;
  width: 32px;
}
