.circle {
  margin-right: 8px;
}

.emailModalDiv {
  gap: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.buttonContainer {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
}
