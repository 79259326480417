.warningIcon {
  margin-left: 8px;
  background-color: rgb(233, 175, 29);
  color: var(--text-color-secondary);
  border-radius: 12px;
  padding: 3px 4px 5px;
  /* Try to make the icon look centered*/
}

.tooltip {
  max-width: 300px;
  white-space: pre;
}

.verticalContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.horizontalContainer {
  display: flex;
  gap: 8px;
  align-items: center;
}
