.container {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.warningContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
