.container {
  width: 380px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px;
  background-color: var(--surface-card);
  border-radius: 6px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  gap: 16px;
}

.collapseButton {
  position: absolute;
  right: calc(380px + 50px + 10px);
  bottom: 60px;
  height: 40px;
}
.collapseButton.collapsed {
  right: -10px;
}

.inputContainerWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.inputContainer {
  display: flex;
  gap: 12px;
  align-items: center;
}

.textInput {
  width: 100%;
  background-color: var(--surface-100);
}

.inputButton {
  height: 44px;
  width: 44px;
  flex-shrink: 0;
}

.conversationContainer {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  color: var(--text-color);
  gap: 12px;
  overflow-y: auto;
  margin-right: -12px;
  margin-left: -12px;
  padding: 12px;
}

.conversationBubble {
  max-width: calc(100% - 40px);
  padding: 0px 16px;
  border-radius: 12px;
}

.emptyStateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 0;
  flex-grow: 1;
  gap: 12px;
}

.sampleQuestionContainer {
  font-family: var(--font-family);
  color: var(--text-color);
  padding: 16px;
  width: 100%;
  border-radius: 12px;
  background-color: var(--surface-100);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.iconRow {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--primary-500);
}
