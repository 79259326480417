.container {
  flex-direction: column;
  display: flex;
  gap: 40px;
}

.headerText {
  margin: 0px;
}

.markupContainer {
  display: flex;
  gap: 12px;
  align-items: center;
}

.barGraphContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.barGraphComponent {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.barRowContainer {
  display: flex;
  align-items: center;
}

.ratingContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  background-color: var(--surface-100);
  border: 1px solid var(--surface-border);
  width: 160px;
  padding: 12px;
  font-size: 14px;
}

.rangeContainer {
  display: flex;
  flex-direction: column;
  width: 16px;
  align-items: center;
  justify-content: space-between;
  margin-right: 24px;
}

.rangeText {
  font-size: 11px;
  color: var(--text-color-secondary);
}

.badge {
  position: absolute;
  top: -6px;
  right: -6px;
}

.lossDiv {
  position: absolute;
  bottom: 0;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
}

@media print {
  .lossDiv {
    box-shadow: none;
  }
}

.lossText {
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
  color: var(--text-color-secondary);
  transform: translateY(100%);
  position: absolute;
  bottom: -2px;
  left: 2px;
}

.lossRed {
  background-color: var(--red-200);
}

.lossOrange {
  background-color: var(--orange-200);
}

.lossYellow {
  background-color: var(--purple-200);
}
