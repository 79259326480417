.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.toolbar {
  height: 72px;
  flex-wrap: unset;
  background-color: transparent;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 8px;
  border-width: 0;
}

.contentContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 24px;
}

.linkButton {
  font-size: 12px;
  color: var(--text-color);
  letter-spacing: 1.5px;
}

.wordmark {
  display: flex;
}

@media (max-width: 700px) {
  .wordmark {
    display: none;
  }
}

@media (max-height: 700px) {
  .footer {
    display: none;
  }
}
