.articleCardContainer {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 28px;
  flex-wrap: wrap;
  padding: 12px 32px;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.articleCard {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 300px;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0 2px rgba(106, 198, 205, 0.8), 0 0 4px rgba(106, 198, 205, 0.6),
    0 0 6px rgba(106, 198, 205, 0.4);
  cursor: pointer;
}

.articleCard:hover,
.articleCard::selection {
  box-shadow: 0 0 5px rgba(106, 198, 205, 0.8),
    0 0 10px rgba(106, 198, 205, 0.6), 0 0 15px rgba(106, 198, 205, 0.4);
}

.articleTitleText {
  margin: 12px;
  font-weight: bold;
  font-size: 22px;
  color: var(--text-color);
  font-family: var(--font-family);
  letter-spacing: 1.5px;
  flex-shrink: 0;
  height: 100px;
}

.mainImg {
  flex-shrink: 1;
  height: auto;
  width: 100%;
  height: 220px;
  object-fit: cover;
}
