.search {
  width: 80%;
  margin: 16px auto 16px;
}

.spinner {
  display: flex;
  justify-content: center;
}

.terms {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 16px;
}
