.container {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  padding-top: 24px;
}

.contentContainer {
  flex-grow: 1;
  min-height: 0;
  min-width: 0;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  max-width: 672px;
  gap: 24px;
}

.headerText {
  color: var(--text-color);
  font-family: var(--font-family);
  margin: 0px;
}

.articleText {
  font-size: 18px;
  line-height: 150%;
  color: var(--text-color);
}

.authorContainer {
  display: flex;
  gap: 12px;
  align-items: center;
}

.authorImg {
  height: 32px;
  width: 32px;
  border-radius: 16px;
}
